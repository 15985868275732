#datagrid-filter {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  #datagrid-filter {
    grid-template-columns: 1fr;
  }
}
